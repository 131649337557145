<template>
  <b-card-code title="Register as channel partner">
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="7">
            <b-media class="d-flex flex-column" no-body>
              <b-media-aside>
                <b-link>
                  <b-img ref="previewEl" rounded :src="profileImage && profileImage !== null
                      ? profileImage
                      : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                    " height="80" />
                </b-link>
              </b-media-aside>
              <b-media-body class="mt-75 ml-75">
                <b-button variant="primary" size="sm" class="mb-75 mr-75" @click="handleClickProfileImage">
                  Upload
                  <div style="display: none">
                    <b-form-file v-model="profileImage_file" id="fileUpload" accept="image/*"
                      @input="handleFileChange($event, 'profileimage')" />
                  </div>
                </b-button>
                <b-button variant="outline-secondary" size="sm" class="mb-75 mr-75" @click="removeprofileImage">
                  Reset
                </b-button>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="12"></b-col>
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input v-model="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" maxlength="50" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" maxlength="50" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Father/Husband Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="father/husband name">
                <b-form-input v-model="fathername" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Name" maxlength="50" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|email" name="Email">
                <b-form-input v-model="email" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input v-model="mobile" type="number|10" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Birth Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Birth Date">
                <flat-pickr v-model="birthdate" placeholder="Select Date" class="form-control" :config="{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                }" style="background: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select v-model="gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                  :options="['Male', 'Female']" placeholder="None" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Password</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required|password" name="Password">
                <b-input-group class="input-group-merge">
                  <b-form-input v-model="password" placeholder="Enter Password" :type="passwordFieldTypeNew" />

                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Joining Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Joining Date">
                <flat-pickr v-model="joiningdate" placeholder="Select Date" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Marital Status</label>
              <v-select v-model="maritalstatus" placeholder="None" :options="['Married', 'Unmaried']" />
            </b-form-group>
          </b-col>
          <b-col md="4" :style="maritalstatus == 'Married' ? '' : 'display:none'">
            <b-form-group>
              <label>Anniversary Date</label>
              <flat-pickr v-model="anniversarydate" placeholder="Select Date" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 1">
                <b-form-input v-model="curaddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Address Line 2">
                <b-form-input v-model="curaddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="curpincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="curcity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="curcityOption"
                  @input="clickArea($event)" placeholder="None" label="City" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="curarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="curareaOption"
                  placeholder="None" label="PostOfficeName" @input="handleCurPincode()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="curtaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="curstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                  placeholder="None" label="State" @input="handleCurCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Permanent Address</span>
            <b-form-checkbox @input="handlePermanent" class="ml-2" inline>
              Same As Current Address
            </b-form-checkbox>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <b-form-input v-model="peraddressline1" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 1" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <b-form-input v-model="peraddressline2" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Address Line 2" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <validation-provider #default="{ errors }" rules="digits:6" name="pin Code">
                <b-form-input v-model="perpincode" maxlength="6" @input="clickPerPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" type="number" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>City</label>
              <v-select v-model="percity" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="City"
                :options="percityOption" @input="clickPerArea($event)" placeholder="None" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Area</label>
              <v-select v-model="perarea" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perareaOption"
                placeholder="None" label="PostOfficeName" @input="handlePincode()" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="pertaluka" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>State</label>
              <v-select v-model="perstate" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="stateOptions"
                placeholder="None" label="State" @input="handleCity()" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Parent</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Parent">
                <b-form-input disabled v-model="parentcp.fullname" />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3">
                <label>Email</label>
                <b-form-input disabled v-model="parentcp.email" /></b-col>
              <b-col md="3">
                <label>Phone No</label>
                <b-form-input disabled v-model="parentcp.mobile" /></b-col>
              <b-col md="3">
                <label>Username</label>
                <b-form-input disabled v-model="parentcp.username" /></b-col>
              <b-col md="3">
                <label>Photo :</label>
                <b-media-aside>
                  <b-link>
                    <b-img rounded :src="parentcp.profile_image
                        ? parentcp.profile_image
                        : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
                      " height="80" />
                  </b-link> </b-media-aside></b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Bank Details" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input v-model="panno" maxlength="10" @input="validatePanClick($event)"
                placeholder="Enter PAN No" />
              <small v-if="pannoValidate == false" class="text-danger">Enter Valid PAN No</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <b-form-input v-model="aadharcardno" maxlength="14" @input="validateAadharClick($event)"
                placeholder="Enter Aadhar No" />
              <small v-if="aadharcardnoValidate == false" class="text-danger">Enter Valid Aadhar No</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <b-form-input v-model="bankname" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Bank" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <b-form-input v-model="bankbranch" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Branch" rules="required" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No</label>
              <b-form-input v-model="bankaccno" type="number" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Enter Account No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code" />
              <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Front Side</label>
              <b-form-file v-model="file.aadharfrontside" placeholder="Select Photo"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFile($event, 'aadharfrontside', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Back Side</label>
              <b-form-file v-model="file.aadharbackside" placeholder="Select Photo" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFile($event, 'aadharbackside', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan Card</label>
              <b-form-file v-model="file.pancard" placeholder="Select Photo" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFile($event, 'pancard', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.pancard" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cancel Cheque</label>
              <b-form-file v-model="file.cancelcheque" placeholder="Select Photo" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFile($event, 'cancelcheque', 'channelpartner')" />
            </b-form-group>
            <attachment :data="documents.cancelcheque" />
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button variant="primary" type="submit" :disabled="flag" @click.prevent="submitForm($event)" class="mr-4">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import Attachment from "@/components/Attechment.vue";
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BFormFile,
  BInputGroupAppend,
  BInputGroup,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormCheckbox,
} from "bootstrap-vue";
import { email, password } from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    BFormCheckbox,
    Attachment,
    flatPickr,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BFormFile,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      name: "",
      surname: "",
      fathername: "",
      email: "",
      mobile: "",
      address: "",
      area: "",
      profileImage: "",
      profileImage_file: [],
      state: "",
      city: "",
      pincode: "",
      gender: "",
      username: "",
      password: "",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      test: "",
      joiningdate: moment(new Date()).format("DD/MM/yyyy"),
      resigndate: null,
      branch: "",
      parentcp: "",
      reportingauthorityoption: [],
      designationoption: [],
      parentcpoption: [],
      branchoption: [],
      ifEdit: "",
      id: "",
      commissiontype: "",
      commissionOption: [],
      assignToOption: [],
      assignto: "",

      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curcity: "",
      curpincode: "",
      curstate: "",

      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      percity: "",
      perpincode: "",
      perstate: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      birthdate: null,
      panno: "",
      pannoValidate: true,
      aadharcardno: "",
      aadharcardnoValidate: true,
      bankname: "",
      bankbranch: "",
      bankaccno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      documents: {
        photo: "",
        aadharfrontside: "",
        aadharbackside: "",
        pancard: "",
        cancelcheque: "",
        registration_doc: "",
      },
      file: {
        photo: [],
        aadharfrontside: [],
        aadharbackside: [],
        pancard: [],
        cancelcheque: [],
        registration_doc: [],
      },
      maritalstatus: "",
      anniversarydate: "",

      flag: false,
      testpan: "",
      testaadhar: "",
      fromCurState: "",
      fromState: "",

      stateOptions: [],
      passwordFieldTypeNew: "password",
    };
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    if (this.$route.params.parent) {
      this.getparent();
    } else {
      this.$router.push("/login");
    }
    this.getState();
  },
  methods: {
    async getparent() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/auth/getparent/${this.$route.params.parent}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.parentcp = json.data && json.data.id ? json.data : false;
          if (!this.parentcp) this.$router.push("/login");
        })
        .catch((error) => {
          this.$router.push("/login");
        });
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    handlecommission(e) {
      if (e) {
        this.rankoptions = e.commission_items ? e.commission_items : [];
      } else {
        this.rankoptions = [];
      }
    },
    handlePermanent(e) {
      if (e == true) {
        this.perarea = this.curarea;
        this.perstate = this.curstate;
        this.percity = this.curcity;
        this.pertaluka = this.curtaluka;
        this.perpincode = this.curpincode;
        this.peraddressline1 = this.curaddressline1;
        this.peraddressline2 = this.curaddressline2;
      } else {
        this.perarea = "";
        this.perstate = "";
        this.percity = "";
        this.pertaluka = "";
        this.perpincode = "";
        this.peraddressline1 = "";
        this.peraddressline2 = "";
      }
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },
    async handleFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.profileImage = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePincode() {
      if (this.fromState == true) {
        this.perpincode = this.perarea.Pincode;
      }
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.percity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.perareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCity() {
      this.perareaOption = [];
      this.percityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.perstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.percityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickPerArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }
      // this.perareaOption = []
      // this.perStateData.map((item) => {
      //   if (item.City == e) {
      //     this.perareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPerPincode() {
      this.perstate = "";
      this.perarea = "";
      this.percity = "";
      this.perareaOption = [];
      this.percityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.perpincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.perpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.percityOption = [];
            this.perareaOption = [];

            this.perStateData = response.data.data;
            this.perareaOption = response.data.data;
            this.perstate = response.data.data[0];
            this.percityOption.push(response.data.data[0]);

            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.percityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
      // this.curareaOption = []
      // this.curStateData.map((item) => {
      //   if (item.City == e) {
      //     this.curareaOption.push(item.PostOfficeName)
      //   }
      // })
    },
    async clickPincode() {
      this.curstate = "";
      this.curarea = "";
      this.curcity = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curareaOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateAadharClick(e) {
      const regex = new RegExp(/^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/);
      this.aadharcardno.length == 4
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      this.aadharcardno.length == 9
        ? (this.aadharcardno = this.aadharcardno.concat(" "))
        : this.aadharcardno;
      if (regex.test(this.aadharcardno) == true) {
        this.aadharcardnoValidate = true;
      } else {
        this.aadharcardnoValidate = false;
      }
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    async handleFile(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestoption = {
        method: "POST",
        headers: {
          "Contant-type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestoption)
        .then((response) => {
          this.documents[name] =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => {
          this.flag = false;
          console.log(error, "error");
        });
    },
    onClickBack() {
      this.$router.push("/login");
    },
    submitForm(e) {
      const data = {
        name: this.name,
        fathername: this.fathername,
        surname: this.surname,
        email: this.email,
        mobile: this.mobile,
        address: this.address,
        area: this.area,
        state: this.state,
        city: this.city,
        pincode: this.pincode,
        gender: this.gender,
        username: this.username,
        password: this.password,
        joiningdate: this.joiningdate,
        resigndate: this.resigndate,
        branch: this.branch.id,
        parent: this.parentcp.id,
        commissiontype: this.commissiontype ? this.commissiontype.id : 0,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        peraddressline1: this.peraddressline1,
        peraddressline2: this.peraddressline2,
        perarea: this.perarea ? this.perarea.PostOfficeName : "",
        pertaluka: this.pertaluka,
        percity: this.percity ? this.percity.City : "",
        perpincode: this.perpincode,
        perstate: this.perstate ? this.perstate.State : "",
        birthdate: this.birthdate,
        panno: this.panno,
        aadharcardno: this.aadharcardno,
        commission_rank: this.commission_rank ? this.commission_rank.id : "",
        bankname: this.bankname,
        bankbranch: this.bankbranch,
        bankaccno: this.bankaccno,
        ifsccode: this.ifsccode,
        photo: this.documents.photo,
        aadharfrontside: this.documents.aadharfrontside,
        aadharbackside: this.documents.aadharbackside,
        pancard: this.documents.pancard,
        cancelcheque: this.documents.cancelcheque,
        registration_doc: this.documents.registration_doc,
        profile_image: this.profileImage,
        maritalstatus: this.maritalstatus,
        anniversarydate: this.anniversarydate,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          success == false ||
          this.pannoValidate == false ||
          this.ifsccodeValidate == false
        ) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.pannoValidate == true && this.ifsccodeValidate == true) {
          this.flag = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: "post",
            url: `${baseApi}/signup-channelpartner`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/login");
            })
            .catch((error) => {
              this.flag = false;

              let message = "";
              const code = error.toString().includes("409");
              if (code) {
                for (let key in error.response.data.message.original) {
                  message += error.response.data.message.original[key][0] + " ";
                }
              }
              this.$swal({
                title: "Error!",
                text: code ? message : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
